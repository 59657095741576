<template>
  <el-container style="height:100%; overflow: auto;">
    <el-header>
      <navtop></navtop>
    </el-header>
    <el-main>
      <div class="contentmain" style="margin-top: 20px;" :style="{'min-height': sheight+'px' }">
        <div class="woshou" v-if="$route.path != '/mestall'">
          <div class="box" :class="{'active':active == 1}"
               @click="active = 1,$router.push({path:'/chatall',query:{active:1}})">
            <span>全部消息</span>
          </div>
          <div class="box" :class="{'active':active == 2}"
               @click="active = 2,$router.push({path:'/chatall',query:{active:2}})">
            <span>未读消息</span>
          </div>
          <div class="box" :class="{'active':active == 3}"
               @click="active = 3,$router.push({path:'/chatall',query:{active:3}})">
            <span>已握手</span>

          </div>
          <div class="box" :class="{'active':active == 4}"
               @click="active = 4,$router.push({path:'/chatall',query:{active:4}})">
            <span>已接受offer</span>
          </div>
          <div class="mestall" @click="$router.push('/mestall')">
            <el-badge v-if="mesNum > 0" :value="mesNum" class="item">
              系统通知
            </el-badge>
            <el-badge v-else>
              系统通知
            </el-badge>
          </div>
        </div>
        <div class="center-content">
          <router-view></router-view>
        </div>
      </div>
      <!-- <footerbottom></footerbottom> -->
    </el-main>
  </el-container>
</template>

<script>
import navtop from '../public/navtop.vue';
// import footerbottom from '../public/footerbottom.vue';
export default {
  components: {
    navtop,
    // footerbottom
  },
  data() {
    return {
      active: 1,
      sheight: '',
      mesNum: 0,
    };
  },
  created() {
    let tempHeight = document.documentElement.scrollHeight;
    this.sheight = tempHeight > 960 ? 823 : tempHeight < 460 ? 823 : (tempHeight - 80);
    // console.log("--- chat init, current page height = ", this.sheight);

    this.$api.getMesNoread('get').then(res => {
      this.mesNum = res.data
    })
  },
  mounted() {},
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    gotonext(path, val) {
      // console.log('进入了跳转',path,this.$route.path)
      this.tabindex = val;
      this.$router.push(path)
      // this.$router.push('/deliveryrecord');
    },
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-submenu, /deep/ .el-menu-item {
  padding: 0;

}

// /deep/.is-active{
// 	position: relative;
// }
// /deep/.is-active :after{
// 	position: absolute;
// 	content: '';
// 	width: 4px;
// 	height: 12px;
// 	bottom: 50%;
// 	background: #00BCFF;
// 	left: 2px;
// 	transform: translate(-50%, 60%);
// }
.tebie /deep/ .el-menu-item {
  padding-left: 20px !important;
  color: #666666 !important;
}

.tebie /deep/ .is-active {
  color: #00BCFF !important;
}

.content {
  padding: 20px 0 200px 0;
}

.center-content {
  // height: 600px;
  // background-color: #0000FF;
}


.el-header {
  background-color: #FFFFFF;
  color: #333;
  text-align: center;
  height: 56px !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
}

.el-footer {
  height: 352px !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #F3F3F3;
  margin-top: 56px;
  padding: 0;


}

.woshou {
  height: 42px;
  margin-bottom: 20px;
  // background-color: #FFFFFF;
  display: flex;
  align-items: center;
  position: relative;
  //>.box{
  //	width: 100px;
  //	height: 42px;
  //	color: #111;
  //	background-color: #e7e6e7;
  //	display: flex;
  //	align-items: center;
  //	justify-content: center;
  //	cursor: pointer;
  //}
      & > div {
        padding: 0px 10px 0 30px;
        line-height: 40px;
        background: #dddddd;
        display: inline-block;
        position: relative;
        margin-left: 5px;
      }

      & > div:after {
        content: '';
        display: block;
        border-top: 20px solid #dddddd;
        border-bottom: 20px solid #dddddd;
        border-left: 20px solid #fff;
        position: absolute;
        right: -20px;
        top: 0;
      }

      & > div:after {
        content: '';
        display: block;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid #dddddd;
        position: absolute;
        right: -20px;
        top: 0;
        z-index: 10;
      }

      & > div:before {
        content: '';
        display: block;
        border-top: 20px solid #dddddd;
        border-bottom: 20px solid #dddddd;
        border-left: 20px solid #fff;
        position: absolute;
        left: 0px;
        top: 0;
      }

      & > div:first-child {
        border-radius: 4px 0 0 4px;
        padding-left: 25px;
      }

      & > div:last-child {
        border-radius: 0px 4px 4px 0px;
        padding-right: 25px;
      }

      & > div:first-child:before {
        display: none;
      }

      // & > div:first-child:after {
      // 	content: '';
      // 	border-top: 20px solid #50abe4;
      // 	border-bottom: 20px solid #50abe4;
      // 	border-left: 20px solid #fff;
      // 	position: absolute;
      // 	right: 0px;
      // 	top: 0;
      // }

      & > div:last-child:after {
        display: none;
      }

      & > div:last-child:before {
        display: none;
      }

      & > div.active {
        background-color: #00bcff;
      }

      & > div.active:after {
        border-left-color: #00bcff;
      }

      & > div.active:before {
        border-top-color: #00bcff;
        border-bottom-color: #00bcff;
      }

      > .active {
        background-color: #409EFF;
        color: #fff;
      }

  .mestall{
    padding: 0 15px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    span{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: red;
      color: #fff;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
  .wone:hover {
    cursor: pointer;
  }

  .wone {
    width: 100px;
    height: 42px;

    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 16px;

    span {
      color: #FFFFFF;
      position: relative;
    }

    .bg-pic {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url(../../assets/images/common/bg-1.png) no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }

  .wtwo:hover {
    cursor: pointer;
  }

  .wtwo {
    width: 100px;
    height: 42px;
    margin-left: -12px;
    color: #111;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    span {
      color: #111;
      position: relative;
    }

    .bg-pic2 {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url(../../assets/images/common/bg-4.png) no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }

  .wthree:hover {
    cursor: pointer;
  }

  .wthree {
    width: 120px;
    height: 42px;
    margin-left: -11px;
    color: #111;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    span {
      color: #111;
      position: relative;
    }

    .bg-pic3 {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url(../../assets/images/common/bg-2.png) no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }
}


</style>
